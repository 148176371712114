<template lang='pug'>
#GPT.main-page.GPT-color.page-noScroll.pb-0.position-relative
    loadingOverlay(v-show='loading' message='Loading . . .' :zIndex='999')
    v-row#page-head.d-flex.align-center.justify-md-space-between
      v-col.d-flex.align-center.px-0.pb-0.px-md-2.pb-md-4(cols='4' md='6' )
        h1.my-0 {{$t('GPT_MENU.CHATBOT')}}
        v-btn.ml-3.mb-1(@click='showAddDialog' width='36' height='36' :ripple='false' color='success' depressed dark fab)
          v-icon(size='16') icon-add
    #page-inner.scroll-x.pt-md-6.pb-0.height_100vh
      .data-area.knowledge_height.scrollbar(v-if='!loading')
          v-container.upgpt-container(max-content)
           v-row.knowledges-row.d-flex.align-center.justify-start.pl-4.pt-4
            v-col(class="mt-1" cols="auto" v-for="(data,index) in ai_bots_list" :key='data.id').pl-0.pt-0
              v-hover
                template(v-slot:default="{ hover }")
                  v-card.rounded-card.knowledges-card-width(:class="hover ? 'knowledges-card-hover' : ''")
                        v-card-title.pa-0.pt-1.d-flex.align-center.justify-space-between
                          div.pa-2.d-flex.align-center(width="50px")
                              v-img.ml-1(v-if='data.channel == "teams"' src='@/assets/images/teams_logo.png' width='55px')
                              v-icon(v-else size='55' :color='get_upchat_channel_icon(data.channel).color' :class='data.channel=="line"? "pl-2":"pl-1"') {{get_upchat_channel_icon(data.channel).icon}}

                          div.mr-3
                            v-avatar.ml-1.knowledges-avatar-28(v-for='item,index in data.knowledges.slice(0, 5)' :key='index' color="#e9f3f8" :title='item.name')
                              span {{ item.name[0] }}
                        v-card-title.pa-0.d-flex.align-center
                          div.pl-1(width="50px")
                            h4(:title='data.bot_name').ellipsis.pl-3 {{ data.bot_name }}
                            v-card-text.mt-0.pb-0
                              div.knowledges-card-description
                                p.multiline-two-ellipsis.t-black(:title='data.description') {{data.description}}
                        v-divider.ma-0.mt-3.ml-3.mr-3
                        v-card-text(@click='').pb-0.knowledges-card-text
                          p.mb-2.span.t-black(:title='data.assistant_role') {{$t("GPT_AIBOTS.AIASSISTANTROLE")}} : 
                              span {{data.assistant_role}}
                          p.mb-2.span.t-black.d-flex.justify-start(:title='getItemNameFromListById(inbox_list,data.inbox_id)') {{$t("GPT_AIBOTS.INBOX")}} : 
                              v-img.ml-1(v-if='data.channel == "teams"' src='@/assets/images/teams_logo.png' max-width='20px')
                              v-icon.ml-1(v-else size='18' :color='get_upchat_channel_icon(data.channel).color') {{get_upchat_channel_icon(data.channel).icon}}
                              span.ml-1 {{getItemNameFromListById(inbox_list,data.inbox_id)}}
                          //- p.mb-1.span(:title='getFormatTime(data.updated_on)') {{$t("GPT_KNOWLEDGE.UPDATED_ON")}} : 
                          //-   span.span {{getFormatTime(data.updated_on)}}
                          //- p.mb-2.span(:title='data.updated_by.last_name+""+data.updated_by.first_name') {{$t("GPT_KNOWLEDGE.UPDATED_BY")}} : 
                          //-   v-avatar.knowledges-avatar-28(color="aliceBlue" :title='data.updated_by.last_name+""+data.updated_by.first_name')
                          //-     span.span(v-if="data.updated_by.usersetting.photo === ''") {{ data.updated_by.last_name }}
                          //-     img(v-else :src="data.updated_by.usersetting.photo" :alt='data.updated_by.usersetting.last_name')
                          
                          div.d-flex.align-center.justify-space-between.pb-3.pt-2
                            v-btn(@click='toDetail(data.id)' width='50%' height='30' color='#18bedf' :ripple='false'  depressed  dark style='border-radius:50px')
                                span {{$t("GENERAL.EDIT")}}
                            div
                              v-btn(fab :icon='!data.active' :color='data.active? "#5fe98b":"#6c757d"' @click='openAibot(data)' width='24' height='24' :ripple='false'  depressed dark :title='$t("ADD_AIBOTS.ONOFF")')
                                v-icon(size='21' style='padding-top:2px;') mdi-power
                              v-btn.ml-2( icon  @click='onDelete(data.id)' width='24' height='24' :ripple='false'  depressed dark :title='$t("GENERAL.DELETE")')
                                v-icon(size='16' color='#6c757d') icon-remove
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitMessageDialog(false)' @keydown.enter='onEmitMessageDialog(true)')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
      success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    v-dialog(v-model='addDialog' width='400' content-class='statusDialog' v-if='addDialog')
      add-dialog(@emitAddDialog='onEmitAddDialog' :inbox_list='inbox_list')
    
</template>

<script>
import loadingOverlay from "@/components/Common/loadingOverlay";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import addDialog from "@/components/GPT_Dialog/AiBots/addAiBot";
import i18n from '/common/plugins/vue-i18n.js';
// import KnowledgeLibraryService from "../services/KnowledgeLibraryService";
import AiBotsService from "../services/AiBotsService";

export default {
  components: {
    loadingOverlay,
    messageDialog,
    successDialog,
    errorDialog,
    addDialog,
  },
  data() {
    return {
      // other
      loading:false,
      isDelete:false,
      // Dialog
      addDialog:false,
      messageDialog: false,
      message: "",
      successDialog: false,
      successMessage: "",
      errorDialog: false,
      errorMessage: "",
      // Data
      ai_bots_list:[],
      botList:[],
      deleteId:null,
      inbox_list:[],
    };
  },
  async created() {
    this.loading = true;
    await this.getListData();
    await this.get_upchat_inbox();
    this.loading = false;
  },
  destroyed() {
  },
  methods: {
    async get_upchat_inbox(){
      await AiBotsService.get_upchat_inbox().then((response)=>{
        let used = new Set(response.data.used);
        let upchat = response.data.upchat.payload.map(el=>{return {"id":el.id,"name":el.name,"channel":this.get_upchat_channel(el.channel_type),"disabled":used.has(el.id.toString())}});
        let teams = response.data.teams;
        if(teams.length > 0){
          for(let teams_item in teams){
            teams[teams_item].disabled = used.has(teams[teams_item].id);
          }
        }
        this.inbox_list.push({ header: 'UpChat'});
        this.inbox_list = this.inbox_list.concat(upchat);
        this.inbox_list.push({ divider: true });
        this.inbox_list.push({ header: 'Teams'});
        this.inbox_list = this.inbox_list.concat(teams);
      })
    },
    get_upchat_channel(data){
        let channel_str = data.toLowerCase()
        let channel_name = ''
        switch(true){
            case channel_str.includes('facebook'):
                return "Facebook"
            case channel_str.includes('line'):
                return "Line"
            case channel_str.includes('webchat'):
                return "Webchat"
            case channel_str.includes('whatsapp'):
                return "WhatsApp"
            case channel_str.includes('sms'):
                return "SMS"
            case channel_str.includes('mail'):
                return "Mail"
            default:
                if(data.split('::').length>1){
                    channel_name = data.split('::')[1];
                }else{
                    channel_name = 'Web';
                }
                return channel_name
        }
    },
    get_upchat_channel_icon(data){
        let channel_str = data.toLowerCase()
        switch(true){
            case channel_str.includes('facebook'):
                return {icon:'mdi-facebook',color:'blue'}
            case channel_str.includes('line'):
                return {icon:'fab fa-line',color:'green'}
            case channel_str.includes('webchat'):
                return {icon:'mdi-earth',color:'blue'}
            case channel_str.includes('whatsapp'):
                return {icon:'mdi-whatsapp',color:'green'}
            case channel_str.includes('sms'):
                return {icon:'mdi-message-text-outline',color:'blue'}
            case channel_str.includes('mail'):
                return {icon:'mdi-email',color:'blue'}
            default:
                return {icon:'mdi-earth',color:'blue'}
        }
    },
    async getListData(){
      await AiBotsService.list().then((response)=>{
        this.ai_bots_list = response.data;
        this.ai_bots_list.forEach(el=>{
          el.knowledges = el.linked_bots_and_knowledge_set.map(item=> {return item.knowledge_lib_id});
          el.knowledges_count = el.linked_bots_and_knowledge_set.length;
        })
      })
    },
    async getBotList(){
      await AiBotsService.select_list().then((response)=>{
        this.botList = response.data;
      })
    },
    async openAibot(data){
      let edit_data = {
        "id" : data.id,
        "active" : !data.active
      }
      await AiBotsService.active(data.id,edit_data).then((response)=>{
        data.active = response.data.active;
      })
    },
    onDelete(id){
      this.deleteId = id;
      this.isDelete = true;
      this.message = i18n.t("ADD_AIBOTS.DEL_MSG");
      this.messageDialog = true;
    },
    toDetail(id){
      if(!this.isDelete){
        this.$router.push(this.$route.path+"/"+id);
      }
    },
    getFormatTime(data){
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false };
        let today  = new Date(data);
        if(this.$i18n.locale == "zh_TW")
        {
            return today.toLocaleDateString("zh-TW", options); 
        }else
        {
            return today.toLocaleDateString("en-US", options);
        }                
    },
    onMail: function (mail) {
      return "mailto:" + mail;
    },
    // Dialog Area ----------------------------------------- [Start]
    showAddDialog() {
      this.addDialog = true;
    },
    onEmitAddDialog(val,data){
      if(val){
        data.knowledges = data.linked_bots_and_knowledge_set.map(item=> {return item.knowledge_lib_id});
        data.knowledges_count = data.linked_bots_and_knowledge_set.length;
        this.ai_bots_list.unshift(data);
      }
      this.addDialog = false;
    },
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    async onEmitMessageDialog(val) {
      if(val && this.isDelete){
          await AiBotsService.delete(this.deleteId).then(()=>{
              this.ai_bots_list = this.ai_bots_list.filter(el=>el.id != this.deleteId);
          })
          this.inbox_list = [];
          await this.get_upchat_inbox();
      }
      this.isDelete = false;
      this.deleteId = null,
      this.messageDialog = false;
    },
  },
  watch:{
    "messageDialog":function(){
      if(!this.messageDialog){
        this.isDelete = false;
      }
    }
  }
};
</script>