<template lang='pug'>
  #GPT.main-page.GPT-color.pb-0.position-relative.height_100vh.scroll-y.pb-5
    #page-inner.pb-0()
      loadingOverlay(v-if='loading' message='Loading . . .' :zIndex='999')
      #page-head.d-flex
        v-row.justify-space-between
          v-col(cols='11').d-flex.align-center.px-0.pb-0.px-md-2.pb-md-4.pt-0
            h1.my-0.pt-0 {{$t('GPT_MENU.CHAT_HISTORY')}}
          v-col(cols='1').d-flex.justify-end
            refreshBtn(@EmitRefresh='onEmitRefresh')
      v-container.pt-8.upgpt-container(max-content)
            v-row
              v-col(cols='8').pr-4
                v-card(max-height='78vh' min-height='78vh').scrollbar.pl-5.pr-5.pt-3.rounded-card
                  v-card-text
                    v-row
                      v-col(cols='3')
                          v-text-field(v-model='search' flat dense  prepend-inner-icon='icon-search')
                      v-col(cols='9').pr-0
                        v-row.d-flex.justify-end
                          v-col(cols='4')
                            v-select(v-model='selectBot' :title='getItemNameFromListById(botList,selectBot)' :items='botList' item-value='id' item-text='bot_name' prepend-inner-icon='fas fa-robot' height='30' flat dense )
                          v-col(cols='4')
                            v-select.pb-2(v-model='selectDateMode' :title='getItemNameFromList(dateMode,selectDateMode)' :items='dateMode' item-value='key' item-text='name' prepend-inner-icon='mdi-calendar' height='30' flat dense  @change='searchBotDate')
                          v-col(v-if='selectDateMode == 5' cols='4')
                            v-menu(min-width='496px' ref="menu" v-model="dateMenu" :close-on-content-click="false" transition="scroll-y-transition" offset-y ) 
                                  template(v-slot:activator="{ on, attrs }")
                                      v-text-field.pt-0(v-model='selectDateText' :title='selectDateText' v-bind="attrs" v-on="on" flat dense  prepend-inner-icon='mdi-calendar-multiple' )
                                  date-picker(v-model='selectDate' type="date" range  inline :showSecond='false' value-type='format' format='YYYY-MM-DD' @change="dateChange($event)")
                  
                    v-data-table.pr-4.mt-2(
                      :item-class="rowClass"
                      :headers="tableheaders",
                      :items="analysisData",
                      :items-per-page="itemPerPage",
                      :page.sync="tablePage",
                      page-count="pageCount",
                      hide-default-footer="hide-default-footer",
                      :loading='listLoading' 
                      loading-text='loading...'
                      @click:row="selectMsg($event)"
                    )
                      template( v-slot:item.ai_bot_id_id="{ item }" )
                          .ellipsis(:title='getItemNameFromListById(botList,item.ai_bot_id_id)' height='36px')
                          div(class='d-flex justify-left' :title='getItemNameFromListById(botList,item.ai_bot_id_id)')
                              span {{getItemNameFromListById(botList,item.ai_bot_id_id)}}
                      template( v-slot:item.channel="{ item }" )
                          .ellipsis(:title='item.channel' height='36px')
                          div(class='d-flex justify-left' :title='get_upchat_channel(item.channel)')
                              v-img(v-if='item.channel == "teams"' src='@/assets/images/teams_logo.png' max-width='20px')
                              v-icon(v-else :color='get_upchat_channel_icon(item.channel).color' size='20') {{get_upchat_channel_icon(item.channel).icon}}
                      template( v-slot:item.updated_on="{ item }" )
                          .ellipsis(:title='item.updated_on' height='36px')
                          div(class='d-flex justify-left' :title='getFormatTime(item.updated_on)')
                              span {{getFormatTime(item.updated_on)}}
                            
                    v-pagination.mt-2(v-model='tablePage' :length='pageCount' circle :total-visible="10")
              v-col(cols='4').pl-0
                v-card.rounded-card.d-flex.justify-center.align-center(v-if="!selectMassage" style='background-color:#ecedf3;' max-height='78vh' min-height='78vh' align='center')
                      div
                        v-img(src="@/assets/images/conversation.png" max-width="13vw" )
                        h5.ma-0.t-black {{$t("GPT_CHAT_HISTORY.L_SELECT")}}
                        
                v-card.v-card-scroll.rounded-card(v-else max-height='78vh' min-height='78vh')
                  v-card-title.d-flex.justify-center(id='msgTitle')
                    v-row
                      v-col(cols='12').pa-2.d-flex.justify-space-between
                        div
                          h5.mb-1 {{selectMassage.conversation_username}}
                          div.pt-0.mt-0(v-if='selectMassage.channel == "teams"' style='max-height:16px;').d-flex
                            v-img.mr-1(v-if='selectMassage.channel == "teams"' src='@/assets/images/teams_logo.png' max-width='16px' max-height='16px')
                            span.small_word(style='margin-top:-7px;') {{get_upchat_channel(selectMassage.channel)}}
                          div.pt-0.mt-0(v-else style='max-height:16px;').d-flex
                            v-icon.pt-0.mt-0.mr-1( :color='get_upchat_channel_icon(selectMassage.channel).color' size='16') {{get_upchat_channel_icon(selectMassage.channel).icon}}
                            span.small_word(style='margin-top:-7px;') {{get_upchat_channel(selectMassage.channel)}}
                        div.mt-2
                          v-btn(icon @click='msgSearchBtn = !msgSearchBtn')
                            v-icon icon-search
                      //- v-col(cols='6').pr-0.mt-3.pb-0
                      //-   v-text-field(v-model='searchMsg' flat dense  prepend-inner-icon='icon-search')
                      //- div v-if="newPost" class="float_box_post ma-0 pa-0" 
                  v-card-title(v-if='msgSearchBtn' style='background-color: #ebeff1;')
                    v-menu(max-height="110px"  v-model='msgMenu' id="searchArea" :close-on-content-click="false" :close-on-click='clickToClose'  transition="scroll-y-transition" offset-y ) 
                          template(v-slot:activator="{ on, attrs }")
                              v-text-field( v-model='searchMsg' clearable :placeholder='$t("GPT_CHAT_HISTORY.SEARCH")' flat dense  prepend-inner-icon='icon-search' v-bind="attrs" v-on="on" @keydown.enter.prevent="goSearchMsg").position-relative
                                  template(v-slot:append)
                                      span.small_word.pt-1.mr-2 {{searchMsgCount==0? 0:selectedMsgIndex+1}}/{{searchMsgCount}}
                                      v-icon(size='20' color=""  @click='msgUp()' ) mdi-chevron-up
                                      v-icon(size='20' color=""  @click='msgDown()' ) mdi-chevron-down
                                  template(v-slot:append-outer)
                                      v-icon(size='20' color="red"  @click='msgSearchBtn=false') mdi-window-close  
                          v-list(attach='searchArea' v-if='searchMsgList.length > 0' dense elevation='0').pa-0
                            v-list-item-group(v-model="selectedMsgIndex" color="primary" )
                              v-list-item(v-for='item,index in searchMsgList' :key='item.index' @click='goToMsg(index)' :disabled='selectedMsgIndex == index' :id="'list-item-' + (item.id)") 
                                span.ellipsis(v-if='item.type=="answer"') {{"Bot"}} : {{item.answer}}
                                span.ellipsis(v-if='item.type=="question"') {{selectMassage.conversation_username}} : {{item.question}}
                  v-divider.pa-0.ma-0
                  v-card-text.v-card-scroll__chat.pr-2(height='400' id='chatBox' )
                    v-spacer.mt-10.small_space(v-for='item,index in selectMessageData' :key='item.id' :ref="'msg-'+item.id" id="trackMsg" )
                      //- 聯絡人
                      v-row.chat_icon_div.d-flex.justify-end(v-if='item.type=="Question"' color="rgba(0,0,0,0)" flat class="mb-0")
                        //- div.chat_icon_div(v-if='item.question')
                        //-   v-btn.chat_icon_bottom(width='0' height='32' color='green' plain border-0 @click='onCopyMsg(item.question)') {{$t('GENERAL.COPY')}}
                        v-card.rounded-card(class="mr-6" max-width="350px" color="#8ee386"  )
                          v-list-item(two-line)
                            v-list-item-content
                              div.upchat-message-div.d-flex.justify-end.mb-4
                                span.can-select-text(v-html='highlight(item.question)' style='font-size:1rem;')
                              v-list-item-subtitle.d-flex.justify-end 
                                span.small_word {{getLittleFormatTime(item.updated_on)}} 
                        v-avatar.chat_icon_bottom(class="" size="18" elevation="10")
                          v-icon.chat_icon_backgroung_color.small_word(:title=`selectMassage.conversation_username`) {{ (selectMassage.conversation_username).slice(0,1) }}

                      //- 機器人
                      v-row.chat_icon_div(v-else color="rgba(0,0,0,0)" flat class="mb-0" )
                        //- v-avatar(class=" mr-2" size="30" elevation="10")
                        //-   v-icon.mx-auto.chat_icon_backgroung_color(title=`Bot` ) {{ 'B' }}
                        v-card.rounded-card(class="" max-width="350px")
                          v-list-item(two-line)
                            v-list-item-content
                              div.upchat-message-div.d-flex.justify-start.mb-4
                                span.can-select-text(v-html='highlight(item.answer)' style='font-size:1rem;')
                              v-list-item-subtitle.d-flex.justify-start 
                                span.small_word {{getLittleFormatTime(item.updated_on)}} 
                        //- div.chat_icon_div(v-if='item.answer')
                        //-   v-btn.chat_copy_rigth(width='0' height='32' color='green' plain border-0 @click='onCopyMsg(item.answer)' ) {{$t('GENERAL.COPY')}}
                          
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitMessageDialog(false)' @keydown.enter='onEmitMessageDialog(true)')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
      success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    v-dialog(v-model='addDialog' width='400' content-class='statusDialog' v-if='addDialog')
      add-dialog(@emitAddDialog='onEmitAddDialog')
    
</template>

<script>
import refreshBtn from "@/components/Common/refreshBtn";
import loadingOverlay from "@/components/Common/loadingOverlay";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import addDialog from "@/components/GPT_Dialog/Knowledge/addKnowledge";
import i18n from '/common/plugins/vue-i18n.js';
import barChart from '../assets/script/barChart';
import lineChart from '../assets/script/lineChart';
import DatePicker from 'vue2-datepicker';
import UsageAnalysisService from "../services/UsageAnalysisService";
import AiBotsService from "../services/AiBotsService";

export default {
  components: {
    loadingOverlay,
    messageDialog,
    successDialog,
    errorDialog,
    addDialog,
    barChart,
    lineChart,
    DatePicker,
    refreshBtn,
  },
  data() {
    return {
      clickToClose:true,
      msgMenu:false,
      msgSearchBtn:false,
      selectedMsgIndex:-1,
      searchMsgList: [],
      // other
      awaitingSearch:false,
      loading:false,
      chartLoading:false,
      listLoading:false,
      isDelete:false,
      dateMenu:false,
      // Dialog
      addDialog:false,
      messageDialog: false,
      message: "",
      successDialog: false,
      successMessage: "",
      errorDialog: false,
      errorMessage: "",
      // Data
      price_data:{},
      selectModel:0,
      modelList:[
        {id:0,name:i18n.t('GENERAL.ALL')},
        {id:3.5,name:"GPT 3.5"},
        {id:4,name:"GPT 4"},
      ],
      selectDate:'',
      selectDateText:'',
      showTokenChart:true,
      lastMonthToken:0,
      lastMonthTokenUS:0,
      thisMonthToken:0,
      thisMonthTokenUS:0,
      chartPrice:0,
      chartPriceUS:0,
      perMsgPrice:0,
      perMsgPriceUS:0,
      analysisData:[],
      tablePage: 1,
      pageCount: 0,
      itemPerPage: 10,
      tableheaders: [
          {
              text: i18n.t('GPT_ANALYSIS.TABLE_HEADER_1'),
              align: 'left',
              sortable: false,
              value: 'bot_name',
              class: "white lighten-5",
          },
          {
              text: i18n.t('GPT_CHAT_HISTORY.CHANNEL'),
              align: 'left',
              sortable: false,
              value: 'channel',
              class: "white lighten-5",
          },
          {
              text: i18n.t('GPT_CHAT_HISTORY.C_USER_NAME'),
              align: 'left',
              sortable: false,
              value: 'conversation_username',
              class: "white lighten-5",
          },
          {
              text: i18n.t('GPT_CHAT_HISTORY.LAST_CHAT_TIME'),
              align: 'left',
              sortable: false,
              value: 'updated_on',
              class: "white lighten-5",
          },
      ],
      selectBot:0,
      botList:[
        {id:0,bot_name:i18n.t('GENERAL.ALL')}
      ],
      selectDateMode: 1,
      dateMode: [
          { key: 1, name: i18n.t('DASHBOARD.THIS_YEAR') },
          { key: 2, name: i18n.t('DASHBOARD.THIS_SEASON') },
          { key: 3, name: i18n.t('DASHBOARD.THIS_MONTH') },
          { key: 4, name: i18n.t('DASHBOARD.THIS_WEEK') },
          { key: 5, name: i18n.t('DASHBOARD.SELECT_DATE') },
      ],
      lastDateMode: [
          { key: 1, name: i18n.t('OP_DATE.LAST_YEAR') },
          { key: 2, name: i18n.t('OP_DATE.LAST_QUARTER') },
          { key: 3, name: i18n.t('OP_DATE.LAST_MONTH') },
          { key: 4, name: i18n.t('OP_DATE.LAST_WEEK') },
          { key: 5, name: i18n.t('DASHBOARD.SELECT_DATE') },
      ],
      search:'',
      searchMsg:'',
      searchMsgCount:0,
      lastSearchMsg:'',
      chatMessage:[],
      selectMassage:null,
      selectMessageData:null,
    };
  },
  async created() {
    this.loading = true;
    await this.get_bot_list();
    await this.getListData();
    this.loading = false;
  },
  destroyed() {
  },
  methods: {
    highlight(text) {
        if(this.searchMsgList.length == 0) {
            return text;
        }
        return text.replace(new RegExp(this.lastSearchMsg, "gi"), match => {
            return '<span class="highlight">' + match + '</span>';
        });
    },
    goToMsg(idx){
      this.selectedMsgIndex = idx;
      this.scrollToMsg();
    },
    scrollToMsg(){
      if(this.searchMsgList.length>0){
        this.$refs["msg-"+this.searchMsgList[this.selectedMsgIndex].id][0].scrollIntoView({ behavior: 'smooth' })
      }
    },
    async goSearchMsg(){
      if(this.lastSearchMsg != this.searchMsg && this.searchMsg != ''){
        await UsageAnalysisService.chatsingle(this.selectMassage.message_identifier,this.searchMsg).then((response)=>{
        this.searchMsgList = [];
        response.data.forEach((el)=>{
          if(el.question.includes(this.searchMsg)){
            let item = JSON.parse(JSON.stringify(el))
            item.id= "Q-"+item.id;
            item.type = "question";
            this.searchMsgList.push(item);
          }
          if(el.answer.includes(this.searchMsg)){
            let item = JSON.parse(JSON.stringify(el))
            item.id= "A-"+item.id;
            item.type = "answer";
            this.searchMsgList.push(item);
          }
        })
        this.searchMsgCount = this.searchMsgList.length;
        this.selectedMsgIndex = this.searchMsgList.length-1;
      }).then(()=>{
        this.scrollToMsg();
        let last_id = this.searchMsgList[this.searchMsgList.length-1].id;
        document.getElementById(`list-item-${last_id}`).scrollIntoView();
      })
        this.lastSearchMsg = this.searchMsg;
      }
    },
    onCopyMsg(event){
      const clipboardData =
      event.clipboardData ||
      window.clipboardData ||
      event.originalEvent?.clipboardData ||
      navigator.clipboard;
      clipboardData.writeText(event);
    },
    msgUp(){
      if(this.searchMsgList.length==0){
        return;
      }
      this.clickToClose = false;
      this.selectedMsgIndex > 0 ? this.selectedMsgIndex--:null ;
      this.scrollToMsg();
      setTimeout(()=>{
        this.clickToClose = true;
      },0)
    },
    msgDown(){
      if(this.searchMsgList.length==0){
        return;
      }
      this.clickToClose = false;
      this.selectedMsgIndex < this.searchMsgList.length-1 ? this.selectedMsgIndex++:null ;
      this.scrollToMsg();
      setTimeout(()=>{
        this.clickToClose = true;
      },0)
    },
    async selectMsg(data){
      this.msgSearchBtn = false;
      if(this.selectMassage == data){
        return;
      }
      await (this.selectMassage = data);
      await this.getMessage();
    },
    async getMessage(){
      await UsageAnalysisService.chatsingle(this.selectMassage.message_identifier,this.search).then((response)=>{
        let arr = [];
        response.data.forEach((el)=>{
          arr.push({id:"Q-"+el.id,question:el.question,updated_on:el.updated_on,type:"Question"},
                   {id:"A-"+el.id,answer:el.answer,updated_on:el.updated_on,type:"Answer"})
        });
        this.selectMessageData = arr;
      }).then(()=>{
        var objDiv = document.getElementById("chatBox");
        objDiv.scrollTop = objDiv.scrollHeight;
      })
    },
    get_upchat_channel(data){
        let channel_str = data.toLowerCase()
        let channel_name = ''
        switch(true){
            case channel_str.includes('facebook'):
                return "Facebook"
            case channel_str.includes('line'):
                return "Line"
            case channel_str.includes('webchat'):
                return "Webchat"
            case channel_str.includes('whatsapp'):
                return "WhatsApp"
            case channel_str.includes('sms'):
                return "SMS"
            case channel_str.includes('mail'):
                return "Mail"
            case channel_str.includes('teams'):
                return "Teams"
            default:
                if(data.split('::').length>1){
                    channel_name = data.split('::')[1];
                }else{
                    channel_name = 'Web';
                }
                return channel_name
        }
    },
    get_upchat_channel_icon(data){
        let channel_str = data.toLowerCase()
        switch(true){
            case channel_str.includes('facebook'):
                return {icon:'mdi-facebook',color:'blue'}
            case channel_str.includes('line'):
                return {icon:'fab fa-line',color:'green'}
            case channel_str.includes('webchat'):
                return {icon:'mdi-earth',color:'blue'}
            case channel_str.includes('whatsapp'):
                return {icon:'mdi-whatsapp',color:'green'}
            case channel_str.includes('sms'):
                return {icon:'mdi-message-text-outline',color:'blue'}
            case channel_str.includes('mail'):
                return {icon:'mdi-email',color:'blue'}
            default:
                return {icon:'mdi-earth',color:'blue'}
        }
    },
    async get_bot_list(){
      await AiBotsService.select_list().then((response)=>{
        this.botList = this.botList.concat(response.data)
        this.botList.forEach(el=>{
          el.name = el.bot_name;
        })
      })
    },
    async getListData(){
      this.listLoading = true;
      let strat = "";
      let end = "";
      let bot = "";
      if(this.selectDateMode == 5 && this.selectDate != ""){
        let arr = this.selectDateText.split(" ~ ");
        strat = arr[0];
        end = arr[1];
      }
      if(this.selectBot != 0){
        bot = this.selectBot;
      }
      await UsageAnalysisService.chatlist(this.selectDateMode,bot,strat,end,this.tablePage,this.itemPerPage,this.search).then((response)=>{
        this.analysisData = response.data.list;
        this.pageCount = response.data.total_page;
      })
      this.listLoading = false;
    },
    dateChange(val){
      this.selectDateText = val.join(" ~ ");
    },
    searchBotDate(){
      if(this.selectDateMode == 5){
        setTimeout(()=>{
          this.dateMenu = true;
        },100)
      }
    },
    rowClass() {
        const rowClass = 'dataTableColor'
        return rowClass;
    }, 
    getFormatTime(data){
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false };
        let today  = new Date(data);
        if(this.$i18n.locale == "zh_TW")
        {
            return today.toLocaleDateString("zh-TW", options); 
        }else
        {
            return today.toLocaleDateString("en-US", options);
        }                
    },
    getLittleFormatTime(data){
      let options = {year: 'numeric', month: '2-digit', day: '2-digit',hour: '2-digit',minute: '2-digit',hour12: false };
        let today = new Date(data);
        return today.toLocaleDateString("zh-TW", options).replaceAll('/','-'); 
    },
    // Dialog Area ----------------------------------------- [Start]
    showAddDialog() {
      this.addDialog = true;
    },
    onEmitAddDialog(){
      this.addDialog = false;
    },
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    onEmitMessageDialog() {
      this.messageDialog = false;
    },
    async onEmitRefresh(){
        this.msgSearchBtn = false;
        this.searchMsg = '';
        this.lastSearchMsg = '';
        this.msgMenu = false;
        this.selectMassage=null,
        this.selectMessageData=null,
        this.searchMsgList = [];
        this.searchMsgCount = 0;
        this.selectedMsgIndex = -1;
        this.loading = true;
        await this.get_bot_list();
        await this.getListData();
        this.loading = false;
    },
  },
  watch:{
    "msgSearchBtn":function(){
      if(!this.msgSearchBtn){
        this.searchMsg = '';
        this.lastSearchMsg = '';
        this.msgMenu = false;
        this.searchMsgList = [];
        this.searchMsgCount = 0;
        this.selectedMsgIndex = -1;
      }
    },
    "searchMsg":function(){
      if(this.searchMsg == '' && this.searchMsgList.length == 0){
        this.lastSearchMsg = '';
      }
    },
    "messageDialog":function(){
      if(!this.messageDialog){
        this.isDelete = false;
      }
    },
    "selectDate":async function(){
      if(this.tablePage != 1){
        this.tablePage = 1;
      }
      await this.getListData();
    },
    "selectBot":async function(){
      if(this.tablePage != 1){
        this.tablePage = 1;
      }
      await this.getListData();
    },
    "selectModel":async function(){
      if(this.tablePage != 1){
        this.tablePage = 1;
      }
      await this.getListData();
    },
    "selectDateMode":async function(){
      if(this.selectDateMode == 5)
      {
        if(this.selectDate != ""){
          if(this.tablePage != 1){
            this.tablePage = 1;
          }
          await this.getListData();
        }
      }else{
        if(this.tablePage != 1){
          this.tablePage = 1;
        }
        await this.getListData();
        }
    },
    "tablePage":async function(){
      await this.getListData();
    },
    "search":async function () {
       if (!this.awaitingSearch) {
          setTimeout(() => {
            this.page = 1
            this.getListData();
            this.awaitingSearch = false;
          }, 1000);
        }
        this.awaitingSearch = true;
    }, 
  }
};
</script>

<style lang="css"> 
  .circle_fill_information{
    height: 20px;
    width: 20px;
    margin: 0 0 1% 2%;
    content: url('https://api.iconify.design/mdi/information-outline.svg?color=%23888bff');
  }

  .openailogo{
    height: 22px;
    width: 22px;
    content: url('https://api.iconify.design/logos/openai-icon.svg');
  }
  .float_search {

        width: 100%; 
        height: auto;
        min-height: auto;
        text-align: left;
        position: absolute;
        top: 69px;
        left: 0px;
        z-index: 9999999;
  }
  .highlight{
    background-color: gold;
    color:green
  }
  .v-card-scroll__chat {
    flex-grow: 1;
    overflow: auto;
    background-color: #93aad4 !important;
  }
</style>